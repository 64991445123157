import { AuthProvider } from '@/types';
import * as SocialIcons from '@/components/presentational/tailwind/Auth/Icons';
import { SocialButton } from '@/components/ui/social-button';
import IconGoogle from '@public/icons/google.svg';

function capitalize(word: string) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

export const RenderProviders = ({
  providers,
  onProviderLoginRequested,
  isLoading,
  isLogin = true,
}: {
  providers: AuthProvider[];
  onProviderLoginRequested: (provider: AuthProvider) => void;
  isLoading: boolean;
  isLogin?: boolean;
}) => {
  const handleProviderClick = (provider: AuthProvider, e: React.MouseEvent) => {
    e.preventDefault(); // Prevent form submission
    onProviderLoginRequested(provider);
  };

  return (
    <>
      {providers.map((provider) => {
        // const AuthIcon = SocialIcons[provider];
        return (
          <SocialButton
            key={provider}
            disabled={isLoading}
            onClick={(e) => handleProviderClick(provider, e)}
            type='button' // Explicitly set the button type to "button"
            data-oid=':.o8ry1'
          >
            {/* <AuthIcon className='size-5' /> */}
            <IconGoogle className='size-5' data-oid=':swvgs1' />
            {isLogin
              ? `Log in with ${capitalize(provider)}`
              : `Sign up with ${capitalize(provider)}`}
          </SocialButton>
        );
      })}
    </>
  );
};
