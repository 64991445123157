"use client"

import * as React from "react"
import Link from "next/link"
import Image from "next/image"
import {DashedDivider4} from "@/components/ui/divider-dashed"
import {Label, Asterisk} from "@/components/ui/label"
import * as Input from "@/components/ui/input"
import {ButtonPrimary} from "@/components/ui/button"
import {FooterCommon} from "../footers"
import {useSignUp} from "@/utils/react-query-hooks"
import {useState, useEffect} from "react"
import {useRouter} from "next/navigation"
import * as z from "zod"
import {useForm, SubmitHandler, Controller} from "react-hook-form"
import {zodResolver} from "@hookform/resolvers/zod"
import IconEmail from "@public/icons/email-1.svg"
import IconLock from "@public/icons/lock.svg"
import IconEyeOpen from "@public/icons/eye-open.svg"
import {useSignInWithProvider} from "@/utils/react-query-hooks"
import {RenderProviders} from "@/components/presentational/tailwind/Auth/RenderProviders"
import {BsTelephone} from "react-icons/bs"
import {IconUser} from "@/components/icons"
import {Notifications} from "@/lib/logsnag"

// Allowlist of common legitimate domains
const LEGITIMATE_DOMAINS = ["gmail.com", "outlook.com", "hotmail.com", "yahoo.com", "icloud.com", "aol.com", "protonmail.com", "mail.com", "zoho.com", "yandex.com", "gmx.com", "live.com", "me.com", "msn.com"]

// Blocklist of common fake domains and suspicious patterns
const FAKE_DOMAIN_PATTERNS = [
    // Exact matches for common test domains
    /^example\.(com|org|net)$/,
    /^invalid\.(com|org|net)$/,
    /^test\.(com|org|net)$/,
    /^localhost$/,
    // Pattern matches for suspicious domains
    /^test\d+\.\w+$/,
    // Common disposable email services - fixed to match only domain part
    /^(mailinator|tempmail|10minutemail|guerrillamail|yopmail|dispostable|sharklasers|trashmail|throwawaymail|fakeinbox)\.(com|net|org|me|info|ru)$/i
]

// Unified validation logic for emails
const validateEmailDomain = (email: string): {isValid: boolean; errorMessage: string | null} => {
    if (!email.includes("@")) {
        return {
            isValid: false,
            errorMessage: "Email must contain an '@' symbol"
        }
    }

    const [localPart, domainPart] = email.split("@")

    if (!domainPart) {
        return {
            isValid: false,
            errorMessage: "Email must have a domain part after '@'"
        }
    }

    const domain = domainPart.toLowerCase()

    // Allow emails from reputable domains
    if (LEGITIMATE_DOMAINS.includes(domain)) {
        return {
            isValid: true,
            errorMessage: null
        }
    }

    // Check for self-referential emails WITHOUT TLDs (allows jack@jack.com)
    if (domain === localPart) {
        return {
            isValid: false,
            errorMessage: "Email domain must include a valid TLD"
        }
    }

    // Check against only the most obvious fake domain patterns
    for (const pattern of FAKE_DOMAIN_PATTERNS) {
        if (pattern.test(domain)) {
            return {
                isValid: false,
                errorMessage: "This domain appears to be invalid or is not allowed"
            }
        }
    }

    // Only reject emails with obvious fake TLDs
    if (/\.(test|invalid|local|localhost|example)$/.test(domain)) {
        return {
            isValid: false,
            errorMessage: "This domain has an invalid top-level domain"
        }
    }

    // Domain must have at least one dot (to have a TLD)
    if (!domain.includes(".")) {
        return {
            isValid: false,
            errorMessage: "Domain must contain at least one dot"
        }
    }

    return {
        isValid: true,
        errorMessage: null
    }
}

// ZeroBounce API validation function with caching and localStorage error handling
const validateEmailWithZeroBounce = async (email: string, setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>): Promise<boolean> => {
    // Try to get cached result with safe localStorage access
    try {
        const cachedResult = localStorage.getItem(`zerobounce_${email}`)
        if (cachedResult) {
            try {
                const {result, timestamp} = JSON.parse(cachedResult)
                // Cache results for 24 hours
                if (Date.now() - timestamp < 24 * 60 * 60 * 1000) {
                    return result === true
                }
            } catch (e) {
                console.warn("Error parsing cached result:", e)
                // Continue with API call if JSON parsing fails
            }
        }
    } catch (e) {
        console.warn("LocalStorage access error:", e)
        // Continue with API call if localStorage is unavailable
    }

    try {
        const apiKey = "dffa24fcb76d41a98d49eaf97a1fae82"
        const response = await fetch(`https://api.zerobounce.net/v2/validate?api_key=${apiKey}&email=${encodeURIComponent(email)}&ip_address=`, {method: "GET",headers: {Accept: "application/json"}})

        if (!response.ok) {
            console.error("ZeroBounce API error:", response.statusText)
            // Send LogSnag notification about API error
            // Notifications.sendNotification("email-validation", "ZeroBounce API Error", "⚠️", true, undefined, {
            //     status: response.status.toString(),
            //     statusText: response.statusText,
            //     email: email
            // })
            return true // Return true to continue with signup on API errors
        }

        const data = await response.json()

        // Log validation result for analysis
        if (data.status) {
            // Notifications.sendNotification("email-validation", `Email Validation: ${data.status}`, data.status === "valid" ? "✅" : "❌", false, undefined, {email, status: data.status})

            // Provide more specific error messages based on status
            if (data.status === "invalid") {
                setErrorMessage("This email address appears to be invalid or doesn't exist")
                try {
                    localStorage.setItem(`zerobounce_${email}`, JSON.stringify({result: false, timestamp: Date.now()}))
                } catch (e) {
                    console.warn("Error saving to localStorage:", e)
                }
                return false
            } 
            // else if (data.status === "catch-all") {
            //     setErrorMessage("Please use your personal email instead of a company catch-all address")
            //     try {
            //         localStorage.setItem(`zerobounce_${email}`, JSON.stringify({result: false, timestamp: Date.now()}))
            //     } catch (e) {
            //         console.warn("Error saving to localStorage:", e)
            //     }
            //     return false
            // } 
            else if (data.status === "spamtrap" || data.status === "abuse") {
                setErrorMessage("This email address has been flagged as problematic. Please use a different email.")
                try {
                    localStorage.setItem(`zerobounce_${email}`, JSON.stringify({result: false, timestamp: Date.now()}))
                } catch (e) {
                    console.warn("Error saving to localStorage:", e)
                }
                return false
            } 
            // else if (data.status === "unknown") {
            //     setErrorMessage("We couldn't verify this email. Please check its spelling or try another one.")
            //     return true // Allow signup attempt with unknown emails
            // }
        }

        // If status is "valid" or we get an API usage limit, allow the submission
        if (data.status === "valid" || data.error?.includes("usage limit")) {
            try {
                localStorage.setItem(`zerobounce_${email}`, JSON.stringify({result: true, timestamp: Date.now()}))
            } catch (e) {
                console.warn("Error saving to localStorage:", e)
            }
            return true
        }

        // Default: reject emails with other statuses
        return false
    } catch (error) {
        console.error("Error validating email with ZeroBounce:", error)
        // Send LogSnag notification about API exception
        Notifications.sendNotification("email-validation", "ZeroBounce API Exception", "🚨", true, undefined, {
            errorMessage: error instanceof Error ? error.message : String(error),
            email: email
        })
        return true // Return true to continue with signup on API errors
    }
}

const schema = z.object({
    name: z.string().min(2, {message: "Name must be at least 2 characters long"}),
    email: z
        .string()
        .email({message: "Invalid email address format"})
        .refine(
            (email) => validateEmailDomain(email).isValid,
            (email) => ({message: validateEmailDomain(email).errorMessage || "Please enter a valid email address"})
        ),
    password: z.string().min(6, {message: "Password must be at least 6 characters long"})
})

type SchemaType = z.infer<typeof schema>

interface PageSignUpProps {
    onSignupSuccess?: () => void
    hideReviewsPanel?: boolean
    hideLoginLink?: boolean
    customLogoPath?: string
    buttonText?: string
    removeTopPadding?: boolean
}

export default function PageSignUp({onSignupSuccess, hideReviewsPanel = false, hideLoginLink = false, customLogoPath, buttonText = "Sign up for free", removeTopPadding = false}: PageSignUpProps = {}) {
    const [showPassword, setShowPassword] = React.useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [isValidatingEmail, setIsValidatingEmail] = useState(false)
    const [emailValidStatus, setEmailValidStatus] = useState<"idle" | "validating" | "valid" | "invalid">("idle")
    const router = useRouter()
    const {
        register,
        control,
        handleSubmit,
        watch,
        formState: {errors}
    } = useForm<SchemaType>({
        resolver: zodResolver(schema),
        mode: "onChange" // Enable validation on change
    })

    const watchedEmail = watch("email") // Watch the email field

    // Email validation status should only change on form submission
    useEffect(() => {
        // Just reset status if email is invalid or missing
        if (!watchedEmail || !watchedEmail.includes("@") || errors.email) {
            setEmailValidStatus("idle")
        }
    }, [watchedEmail, errors.email])

    const signUpMutation = useSignUp({
        onError: (error: any) => {
            if (error.message === "User already registered") {
                setErrorMessage(`An account with this email already exists. You can <a href='/login' class='font-medium underline'>log in instead</a>.`)
            } else {
                setErrorMessage(error.message)
            }
        },
        onSuccess: () => {
            // Track the sign-up event
            if (typeof window !== "undefined" && window.dataLayer) {
                window.dataLayer.push({
                    event: "sign_up",
                    method: "email"
                })
            }
            if (onSignupSuccess) {
                onSignupSuccess()
            } else {
                router.push("/app/dashboard")
            }
        }
    })

    const onSubmit: SubmitHandler<SchemaType> = async (data: SchemaType) => {
        try {
            setIsValidatingEmail(true)
            setErrorMessage(null)
            setEmailValidStatus("validating")

            // First validation with ZeroBounce API - only on form submission
            const isValidEmail = await validateEmailWithZeroBounce(data.email, setErrorMessage)

            setEmailValidStatus(isValidEmail ? "valid" : "invalid")

            if (!isValidEmail) {
                setIsValidatingEmail(false)
                return
            }

            // If email validation passes, proceed with sign up
            signUpMutation.mutate({
                name: data.name,
                email: data.email,
                phone: null,
                password: data.password
            })
        } catch (error) {
            console.error("Error during email validation:", error)
            setEmailValidStatus("idle")
            // If there's an error with validation, still try to sign up
            signUpMutation.mutate({
                name: data.name,
                email: data.email,
                phone: null,
                password: data.password
            })
        } finally {
            setIsValidatingEmail(false)
        }
    }

    const providerMutation = useSignInWithProvider()

    // Email validation status indicator component
    const EmailValidationStatus = () => {
        if (emailValidStatus === "idle" || !watchedEmail || errors.email) return null

        if (emailValidStatus === "validating") {
            return <div className="text-sm text-yellow-500">Validating email...</div>
        }

        if (emailValidStatus === "valid") {
            return <div className="text-sm text-green-500">Email looks good ✓</div>
        }

        if (emailValidStatus === "invalid") {
            return <div className="text-sm text-red-500">Email may not be deliverable</div>
        }

        return null
    }

    return (
        <>
            <div className="flex flex-1 flex-col">
                <div className={`relative flex flex-1 flex-col justify-center ${removeTopPadding ? "pb-12 pt-0" : "py-12 sm:pb-16 sm:pt-[124px]"} ${removeTopPadding ? "items-center" : "[@media(min-height:900px)]:items-center"}`}>
                    <form onSubmit={handleSubmit(onSubmit)} className={`mx-auto flex w-full max-w-[324px] shrink-0 flex-col gap-8 ${customLogoPath || removeTopPadding ? "text-center" : ""}`}>
                        {customLogoPath ? (
                            <Image src={customLogoPath} alt="CreditCaptain logo" width={126} height={40} priority className="mx-auto self-center" />
                        ) : (
                            <Image src="/images/logo.svg" alt="CreditCaptain logo" width={32} height={32} priority className={removeTopPadding ? "mx-auto self-center" : ""} />
                        )}

                        <div>
                            <div className="text-display-m text-neutral-800">Create a new account</div>
                            <div className="mt-2 text-body-s text-neutral-600">Create your free account at CreditCaptain to start improving your scores today.</div>
                        </div>
                        <div className="flex flex-col gap-6">
                            <RenderProviders
                                providers={["google"]}
                                isLogin={false}
                                isLoading={providerMutation.isLoading}
                                onProviderLoginRequested={(provider) => {
                                    providerMutation.mutate({provider})
                                }}
                            />

                            <div className="flex items-center gap-2.5 text-caption-s text-neutral-400">
                                <DashedDivider4 />
                                OR
                                <DashedDivider4 />
                            </div>
                            <div className={`flex flex-col gap-3 ${removeTopPadding ? "text-left" : ""}`}>
                                <div className="flex flex-col gap-1">
                                    <Label htmlFor="name">
                                        Full Name
                                        <Asterisk />
                                    </Label>
                                    <Input.Root>
                                        <Input.Wrapper>
                                            <Input.Icon icon={IconUser} />
                                            <Input.Input id="name" type="text" placeholder="John Doe" {...register("name")} />
                                        </Input.Wrapper>
                                    </Input.Root>
                                    {errors.name && <span className="text-footnote text-red-normal">{String(errors.name.message)}</span>}
                                </div>
                                <div className="flex flex-col gap-1">
                                    <Label htmlFor="email">
                                        Email Address
                                        <Asterisk />
                                    </Label>
                                    <Input.Root>
                                        <Input.Wrapper>
                                            <Input.Icon icon={IconEmail} />
                                            <Input.Input id="email" type="email" placeholder="hello@creditcaptain.com" {...register("email")} />
                                        </Input.Wrapper>
                                    </Input.Root>
                                    {errors.email && <span className="text-footnote text-red-normal">{String(errors.email.message)}</span>}
                                    <EmailValidationStatus />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <Label htmlFor="password">
                                        Password
                                        <Asterisk />
                                    </Label>
                                    <Input.Root>
                                        <Input.Wrapper>
                                            <Input.Icon icon={IconLock} />
                                            <Input.Input id="password" type={showPassword ? "text" : "password"} placeholder="• • • • • • • • • •" {...register("password")} />

                                            <button type="button" onClick={() => setShowPassword((s) => !s)}>
                                                <IconEyeOpen className="size-[18px] text-neutral-400" />
                                            </button>
                                        </Input.Wrapper>
                                    </Input.Root>
                                    {errors.password && <span className="text-footnote text-red-normal">{String(errors.password.message)}</span>}
                                </div>
                            </div>
                            {errorMessage && (
                                <div className="relative rounded-xl bg-red-500 px-4 py-3 text-sm text-white" role="alert">
                                    <span className="block sm:inline" dangerouslySetInnerHTML={{__html: errorMessage}}></span>
                                    <button onClick={() => setErrorMessage(null)} className="absolute right-0 top-0 p-2">
                                        <span className="sr-only">Close</span>
                                        <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                                            <path
                                                fillRule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            )}
                            <ButtonPrimary size="large" className="w-full" type="submit" disabled={signUpMutation.isLoading || isValidatingEmail}>
                                {signUpMutation.isLoading || isValidatingEmail ? (isValidatingEmail ? "Validating Email..." : "Creating Account...") : buttonText}
                            </ButtonPrimary>
                        </div>
                        {!hideLoginLink && (
                            <div className="text-center text-body-s text-neutral-600">
                                Already have an account?{" "}
                                <Link href="/login" className="relative text-action text-neutral-700 after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-0 after:bg-neutral-700 after:transition-all after:duration-300 hover:after:w-full">
                                    Login here
                                </Link>
                            </div>
                        )}
                    </form>
                </div>
            </div>
            <div className={hideReviewsPanel ? "flex justify-center" : ""}>
                <FooterCommon />
            </div>
        </>
    )
}
